import API from 'api';
import history from '../history';
import { addTokensToCookies, deleteCookie, getCookie } from '../libs/cookies';
import { jwtTokenConverter, tokenUpdater } from '../libs/converters';

// ------------------------------------
// Constants
// ------------------------------------
export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILED = 'CHECK_AUTH_FAILED';
export const SEND_AUTH = 'SEND_AUTH';
export const SEND_AUTH_SUCCESS = 'SEND_AUTH_SUCCESS';
export const SEND_AUTH_FAILED = 'SEND_AUTH_FAILED';
export const SEND_IMPERSONATE_AUTH = 'SEND_IMPERSONATE_AUTH';
export const SEND_IMPERSONATE_AUTH_SUCCESS = 'SEND_IMPERSONATE_AUTH_SUCCESS';
export const SEND_IMPERSONATE_AUTH_FAILED = 'SEND_IMPERSONATE_AUTH_FAILED';
export const SEND_IMPERSONATE_LOGOUT = 'SEND_IMPERSONATE_LOGOUT';
export const SEND_IMPERSONATE_LOGOUT_SUCCESS = 'SEND_IMPERSONATE_LOGOUT_SUCCESS';
export const SEND_IMPERSONATE_LOGOUT_FAILED = 'SEND_IMPERSONATE_LOGOUT_FAILED';
export const LOGOUT = 'LOGOUT';

export const SET_CURRENT_AGENCY = 'SET_CURRENT_AGENCY';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// ------------------------------------
// Actions
// ------------------------------------
export const checkAuth = () => async (dispatch) => {
  dispatch({
    type: CHECK_AUTH,
  });

  try {
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');

    if (!accessToken && !refreshToken) {
      dispatch({ type: CHECK_AUTH_FAILED });

      return false;
    }

    let profile = jwtTokenConverter(accessToken);

    if (!profile) {
      const authResponse = await API.auth.getTokensByRefreshToken(refreshToken);
      addTokensToCookies(authResponse);
      profile = jwtTokenConverter(authResponse.access_token);
    }

    const updateProfile = (newProfile) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: newProfile
      });
    };

    if (!profile || profile.url) {
      console.log('check auth failed profile');
      dispatch({
        type: CHECK_AUTH_FAILED,
      });

      return false;
    }

    profile.agencies = await API.agencies.accessible();

    tokenUpdater(
      getCookie('refreshToken'),
      profile.agencies,
      API.auth.getTokensByRefreshToken,
      updateProfile,
      API.agencies.accessible,
    );

    const ADV = profile.agencies.find(({ name }) => name === 'ADV');
    if (ADV) {
      profile.extend.agency.id = ADV.id;
      profile.spaceName = ADV.name;
    } else {
      profile.spaceName = profile.agencies[0].name;
    }


    dispatch({
      type: CHECK_AUTH_SUCCESS,
      profile,
    });

    return true;
  } catch (error) {
    console.log('check auth failed profile', error);
    dispatch({
      type: CHECK_AUTH_FAILED,
      error,
    });

    throw error;
  }
};

export const logIn = (mail, password) => async (dispatch) => {
  dispatch({
    type: SEND_AUTH,
  });

  try {
    const authResponse = await API.auth.getTokensByUserPass(mail, password);

    if (!authResponse) {
      console.log('no auth response?', authResponse);
      dispatch({
        type: SEND_AUTH_FAILED,
      });

      return;
    }
    addTokensToCookies(authResponse);

    const profile = jwtTokenConverter(authResponse.access_token);

    const updateProfile = (newProfile) => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: newProfile
      });
    };

    if (!profile || profile.url) {
      console.log('check auth failed profile', profile);

      return dispatch({
        type: SEND_AUTH_FAILED,
      });
    }


    profile.agencies = await API.agencies.accessible();

    tokenUpdater(
      authResponse.refresh_token,
      profile.agencies,
      API.auth.getTokensByRefreshToken,
      updateProfile,
      API.agencies.accessible,
    );

    const ADV = profile.agencies.find(({ name }) => name === 'ADV');
    if (ADV) {
      profile.extend.agency.id = ADV.id;
      profile.spaceName = ADV.name;
    } else {
      profile.spaceName = profile.agencies[0].name;
    }

    dispatch({
      type: SEND_AUTH_SUCCESS,
      profile,
    });

    return authResponse;
  } catch (error) {
    console.log('error...', error);
    dispatch({
      type: SEND_AUTH_FAILED,
    });

    throw error;
  }
};


export const logOut = () => async (dispatch) => {
  const refreshToken = getCookie('refreshToken');

  if (!refreshToken) return;

  try {
    await API.auth.logOut(refreshToken);

    deleteCookie('refreshToken');
    deleteCookie('accessToken');
    deleteCookie('refreshTokenExpireIn');
    deleteCookie('accessTokenExpireIn');

    dispatch({
      type: LOGOUT,
    });
  } catch (error) {
    console.log('logout error: ', error);
  }
};

const hasAnyOfRoles = (roles, role) => roles.split(',').map(role => role.trim()).includes(role);

export const checkRoles = (roles = '') => (dispatch, getState) => {
  const { profile } = getState().auth;

  return profile && hasAnyOfRoles(roles, profile.role.name);
};

export const setCurrentAgency = (agencyId) => ({
  type: SET_CURRENT_AGENCY,
  payload: agencyId
});

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isAuthorizing: false,
  isAuthorized: false,
  profile: null,
  authorizingError: null,
};

const ACTION_HANDLERS = {
  [CHECK_AUTH]: (state) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false,
    authorizingError: null,
  }),
  [CHECK_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile,
  }),
  [CHECK_AUTH_FAILED]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    authorizingError: action.error,
  }),
  [SEND_AUTH]: (state) => ({
    ...state,
    isAuthorizing: true,
    isAuthorized: false,
  }),
  [SEND_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: true,
    profile: action.profile,
  }),
  [SEND_AUTH_FAILED]: (state) => ({
    ...state,
    isAuthorizing: false,
  }),
  [SEND_IMPERSONATE_AUTH_SUCCESS]: (state, action) => ({
    ...state,
    profile: action.profile,
  }),
  [SEND_IMPERSONATE_LOGOUT_SUCCESS]: (state, action) => ({
    ...state,
    profile: action.profile,
  }),
  [LOGOUT]: (state) => ({
    ...state,
    isAuthorizing: false,
    isAuthorized: false,
    profile: null,
  }),
  [SET_CURRENT_AGENCY]: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      extend: { agency: { id: action.payload, name: '' } }
    }
  }),
  [UPDATE_PROFILE]: (state, action) => ({
    ...state,
    profile: {
      ...state.profile,
      ...action.payload,
      extend: {
        agency: {
          name: '',
          id: action.payload.agencyIds.includes(state.profile.extend.agency.id)
            ? state.profile.extend.agency.id
            : action.payload.agencyIds[0]
        }
      }
    }
  })
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
